import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loading"];

  connect(){
    $(this.loadingTarget).hide();
    this.element.dispatchEvent(new CustomEvent('document-accordion-connected', {bubbles: true}))
  }

  showLoader(){
    $(this.loadingTarget).show();
  }
}
