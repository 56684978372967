import { Controller } from "stimulus"

export default class extends Controller {
  documentAccordionConnected(event){
    var accordionEl = event.target;
    var uploadForm = accordionEl.querySelector(".file-upload-form")
    var previewFileUploadEl = accordionEl.querySelectorAll(".preview-file-upload")
    var deleteFileUploadEl = accordionEl.querySelectorAll("a.delete-file-upload-action")
    var bestInPlaceEl = accordionEl.querySelectorAll(".best_in_place")
    var circlePopupEl = accordionEl.querySelector(".title i.icon")

    $(circlePopupEl).popup();
    $(bestInPlaceEl).best_in_place();
    configureFileUpload(uploadForm);

    this._bindFileUploadPreviewModal(previewFileUploadEl)
    this._bindMessageClose()
    this._bindFileUploadDelete(deleteFileUploadEl)
  }

  handleNewFileUpload(event){
    var previewFileUploadEl = event.target.querySelector(".preview-file-upload")
    var deleteFileUploadEl = event.target.querySelectorAll("a.delete-file-upload-action")

    this._bindFileUploadPreviewModal(previewFileUploadEl)
    this._bindFileUploadDelete(deleteFileUploadEl)
  }

  _bindFileUploadDelete(deleteFileUploadEl){
    $(deleteFileUploadEl).on('click', async function(event){
      event.preventDefault()

      var accordionEl = $(event.target).closest('.document-accordion')
      var accordionUrl = $(accordionEl).data('document-accordion-url')
      var documentId = $(accordionEl).data('document')
      var turboFrame = document.querySelector(`turbo-frame[id*="document_${documentId}"]`)

      try {
        const response = await fetch(event.target.href, {
          method: 'DELETE',
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            'Content-Type': 'application/json'
          }
        })

        if(response.status === 200){
          const data = await response.json();
          toastr.success(data.message);
        } else if(response.status === 599 || response.status === 401){
          const data = await response.json();
          toastr.error(data.message);
        }

        $(turboFrame).attr('src', accordionUrl); // refresh document accordion
      }catch(error){
        toastr.error("Error deleting file");
      }
    });
  }

  _bindFileUploadPreviewModal(previewFileUploadEl){
    $(previewFileUploadEl).on("click", function () {
      var fileUploadId = $(this).data("file-upload-id");
      var fileUploadPreviewModal = $('.file-upload-preview[data-file-upload-id="' + fileUploadId + '"]')

      fileUploadPreviewModal.modal({
        detachable: false,
        onShow: function(){
          var previewFileUploadContainer = this.closest('.file-upload-preview-container')
          $(previewFileUploadContainer).removeClass('hidden')
          $(this).removeClass('legacy')
          $(previewFileUploadContainer).addClass('visible')
        },
        onHide: function(){
          var previewFileUploadContainer = this.closest('.file-upload-preview-container')
          $(previewFileUploadContainer).removeClass('visible')
          $(previewFileUploadContainer).addClass('hidden')
        }
      }).modal('show');
    });
  }

  _bindMessageClose(){
    $(".message.closable .close.icon").on("click",function(){
      $('.message.closable').fadeOut("slow") ;
      return false ;
    });
  }
}
